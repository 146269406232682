<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Beratung <br />
    </h2>
    <article class="container mx-auto p-4 md:p-32">
      <h2 class="text-4xl lg:text-6xl mb-16" style="color: #f27405">
        Beratung
      </h2>
      <div class="flex flex-col lg:flex-row">
        <figure class="mb-4">
          <img
            class="w-96 lg:h-32 rounded-full border border-red-200"
            src="../assets/logo.webp"
            alt="berliner rolladen markisen "
          />
        </figure>
        <p class="px-10 pb-10 text-gray-600">
          <span class="block">
            <span style="color: #f27405"
              >Wir beraten Sie gerne - ob bei uns im Büro oder direkt bei Ihnen
              vor Ort</span
            >. Denn wenn es um hochwertigen Sonnenschutz geht der Sie Jahrelang
            begleiten soll, ist es wichtig auf den Fachmann Ihres Vertrauens zu
            setzen. <br /><br />
            Gerade bei Terrassendächern, Wintergärten, Rollläden und Jalousien
            ist es wichtig auf die richtigen Produkte zu greifen. Anders als
            Baumärkte besitzen wir hierfür die qualitativsten, neusten
            Markenprodukte und das fachspezifische Wissen gelehrter Meister. Bei
            uns bleiben keiner Ihrer Fragen ungeklärt. Wir arbeiten mit vollster
            transparenz - damit Sie sich bei uns am wohlsten fühlen.<br /><br />
            Das Wissen unserer Meister und Monteure wurde durch einen langen
            Ausbildungsweg so gelehrt, dass wir hochwertige Arbeit leisten und
            Ihnen auch nach erfolgreichem Abschluss der Montage für weitere
            Wünsche zur Verfügung stehen.<br /><br />
            <span style="color: #f27405" class="font-bold"
              >Rufen Sie uns jetzt an oder schreiben uns eine E-Mail. Wir melden
              uns schnellstmöglich bei Ihnen zurück.</span
            >
          </span>
        </p>
      </div>

      <contacticons></contacticons>
    </article>
  </section>
</template>
<script>
import contacticons from "../components/contact";
export default {
  name: "App",
  components: {
    contacticons,
  },
};
</script>
